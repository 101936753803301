@import "@/assets/scss/style.scss";

.client-info {
    margin-top: 2rem;

    &__card-title {
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
    }

    &__description {
        display: block;
    }

    &__address-field {
        margin-bottom: 3px;
    }

    &__address-title {
        font-weight: bold;
    }

    &__tag-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__tag {
        background-color: $grey-three;
        text-align: center;
        border-radius: 2px;
        color: $grey-9;
        width: fit-content;
        padding: 3px 8px;
        margin: 0 5px 5px 0;
        font-size: 12px;
    }
}

::v-deep .ant-card-body {
    padding: 12px 17px !important;
}
